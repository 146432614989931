<template>
  <div class="area_list">
    <div class="head_tbl">
      <strong class="screen_out">결과 목록</strong>
      <div class="wrap_btn">
        <div class="pos_right">
          <button
            v-if="!isTempDocs"
            type="button"
            class="btn_medium btn_fourthly"
            @click.prevent="toggleReadYn"
          >
            {{ readYn }}
          </button>
          <button
            v-else
            type="button"
            class="btn_medium btn_secondary"
            @click.prevent="deleteConfirm"
          >
            삭제
          </button>
        </div>
      </div>
    </div>
    <!-- // head_tbl -->
    <div class="tbl_comm tbl_list">
      <table>
        <caption class="ir_caption">
          결과 목록 표
        </caption>
        <colgroup>
          <col v-for="item in listConfig" :key="'col_' + item.key" :style="item.colStyle" />
        </colgroup>
        <thead>
          <tr>
            <th v-for="item in listConfig" :key="'th_' + item.key" scope="col">
              <div v-if="item.type === 'checkbox'" class="box_choice type_alone">
                <!-- 텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 -->
                <div class="item_choice" :class="{ disabled: docList.length < 1 }">
                  <!-- disabled 상태시 disabled 클래스 추가 -->
                  <input
                    id="checkLineAll"
                    type="checkbox"
                    class="inp_choice"
                    name="checkLineAll"
                    :checked="checkAll"
                    :disabled="docList.length < 1"
                    @click="toggleAll"
                  />
                  <label class="lab_choice" for="checkLineAll">
                    <span class="ico_account ico_check">전체 선택</span>
                  </label>
                </div>
              </div>
              <a
                v-else-if="item.isSort"
                href="javascript:void(0);"
                class="link_sort"
                :class="sort.sortOnDraft"
                @click.prevent="onSort(item.key)"
              >
                {{ item.text }}<span class="ico_account" /> </a
              ><!-- 클릭시 sort_on 클래스 토글 -->
              <template v-else>
                {{ item.text }}
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="docList.length < 1">
            <td :colspan="lengthColumn">조회된 문서가 없습니다.</td>
          </tr>
          <tr
            v-for="(document, idx) in docList"
            :key="idx"
            :class="[
              document.prev_read,
              {
                tr_select: checkedDocs.find((item) => item === document.targetPk),
              },
            ]"
            :style="{ 'font-weight': document.readDate ? 'normal' : 'bold' }"
          >
            <!-- 읽기 전 가로 행은 prev_read 클래스 추가하고 읽은 경우 클래스 삭제 -->
            <td
              v-for="item in listConfig"
              :key="'td_' + item.key"
              :class="item.class"
              :title="item.class && item.class.indexOf('td_ellip') > -1 ? document[item.key] : ''"
            >
              <template v-if="item.type === 'checkbox'">
                <div class="box_choice type_alone">
                  <!-- 텍스트 없이 아이콘만 있는 타입 type_alone 클래스 추가 -->
                  <div class="item_choice">
                    <!-- disabled 상태시 disabled 클래스 추가 -->
                    <input
                      :id="document.targetPk"
                      v-model="checkedDocs"
                      type="checkbox"
                      class="inp_choice"
                      name="checkLine"
                      :value="document.targetPk"
                    />
                    <label class="lab_choice" :for="document.targetPk">
                      <span class="ico_account ico_check">선택</span>
                    </label>
                  </div>
                </div>
              </template>
              <template v-else-if="item.type === 'link'">
                <a
                  href="javascript:void(0);"
                  class="link_detail"
                  @click.prevent="docDetail(document)"
                  >{{ unescape(document.title) }}</a
                ><!-- 내용 셀은 link_detail 구조로 래핑 -->
              </template>
              <template v-else-if="item.type === 'empName'">
                <template v-if="document[item.key2] && document[item.key2] !== 'null'">
                  {{ document[item.key] }} ({{ document[item.key2] }})
                </template>
                <template v-else>
                  {{ document[item.key] }}
                </template>
              </template>
              <template v-else-if="item.type === 'date'">
                {{
                  document[item.key]
                    ? $moment(document[item.key], "YYYYMMDDHHmmss").format("YYYY-MM-DD")
                    : ""
                }}
              </template>
              <template v-else-if="item.type === 'datetime'">
                {{
                  document[item.key]
                    ? $moment(document[item.key], "YYYYMMDDHHmmss").format("YYYY-MM-DD HH:mm")
                    : ""
                }}
              </template>
              <template v-else>
                <!-- type : text -->
                {{ document[item.key] }}
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- // tbl_comm -->
    <div class="paging_comm" :class="paginateWrapClass">
      <!-- paginateConfig.pageCount || 0 -->
      <paginate
        v-model="paginateConfig.pageCurrent"
        :click-handler="setPaging"
        :container-class="paginateConfig.ulClass"
        :prev-text="paginateConfig.prevText"
        :next-text="paginateConfig.nextText"
        :page-range="paginateConfig.pageRange"
        :margin-pages="paginateConfig.marginPage"
        :page-count="totalPage"
      />
    </div>
  </div>
</template>

<script>
import { default as CommPaginateMixin } from "@/_approval/mixins/common/commPaginate.js";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
import { targetGubun } from "@/_approval/const/const";
import ApiService from "@/services/ApiService";
import commDocs from "@/_approval/mixins/common/commDocs";
import { isEmpty, unescape } from "lodash";

let initSort = {
  sortField: "COMPLETE_DATE",
  sortFlagDraft: "DESC",
  sortFlagComplete: "DESC",
  sortOnDraft: "",
  sortOnComplete: "",
};

export default {
  name: "StatementListDiv",
  mixins: [CommPaginateMixin, CommLayerMixin, CommToastMixin, commDocs],
  props: {
    // 임시저장문서 여부 (삭제노출)
    isTempDocs: {
      type: Boolean,
      default: false,
    },
    // 테이블 설정 정보
    listConfig: {
      type: Array,
      default: () => [],
    },
    // 전체 페이지 수
    totalPage: {
      type: Number,
      default: 1,
    },
    // 문서 리스트
    docList: {
      type: Array,
      default: () => [],
    },
    // 정렬 기본값
    sortField: {
      type: String,
      default: "COMPLETE_DATE",
    },
    // updateRead API path
    updateReadApi: {
      type: String,
      default: this.$apiPath.APRVL_DOC_READ,
    },
    // deleteTemp API path
    deleteTempApi: {
      type: String,
      default: this.$apiPath.APRVL_DOC_LIST,
    },
    // targetGubun
    targetGubun: {
      type: String,
      default: "",
    },
    // 문서상태
    docStatus: {
      type: String,
      default: "",
    },
    // 결재 상태
    aprvlStatus: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentPage: localStorage.pageNo ? localStorage.pageNo : 1, // 처음에 페이지 정보가 있는 경우 해당 페이지에 대한 리스트를 내보낸다
      // countPerPage: 10,
      sort: { ...initSort },
      // empty: false,
      prevDisable: false,
      nextDisable: false,
      checkedDocs: [],
      totalDocCount: 0,
      checkAll: false,
      readYn: "읽음",
    };
  },
  computed: {
    lengthColumn() {
      return this.listConfig ? this.listConfig.length : 0;
    },
    paginateWrapClass() {
      return this.docList && (!this.docList.length || this.docList.length === 0)
        ? "paging_none"
        : "";
    },
  },
  watch: {
    checkedDocs(checkedDocs) {
      this.checkAll =
        checkedDocs && checkedDocs.length !== 0 && checkedDocs.length === this.totalDocCount;

      if (this.isTempDocs || checkedDocs.length === 0) {
        this.readYn = "읽음";
        return;
      }

      const existNoReadDoc = checkedDocs
        .map((pk) => this.docList.find((e) => e.targetPk === pk))
        .some((e) => e.readDate == null);

      this.readYn = existNoReadDoc ? "읽음" : "안읽음";
    },
    docList() {
      this.totalDocCount = this.docList ? this.docList.length : 0;
      this.paginateConfig.pageCount = this.totalPage;
      if (this.docList && this.docList.length > 0) {
        this.docList.forEach((doc) => {
          if (doc.readYn === "N") {
            doc.prev_read = "prev_read";
          }
        });
        this.prevDisable = this.currentPage > 1;
        this.nextDisable = this.currentPage < this.totalPage;
        this.pagination = [];
        for (let i = -1; i <= 1; i++) {
          let isActive = false;
          let page = this.paginateConfig.pageCurrent + i;
          if (page === this.currentPage) {
            isActive = true;
          }
          if (page > 0 && page <= this.totalPage) {
            this.pagination.push({
              page: page,
              isActive: isActive,
            });
          }
        }
      }
      this.checkedDocs.splice(0);
    },
  },
  created() {
    initSort.sortField = this.sortField;
    this.sort.sortField = this.sortField;
  },
  mounted() {
    this.resetSort(this.currentPage);
  },
  methods: {
    unescape,
    setPaging(page) {
      this.paginateConfig.pageCurrent = Number(page); // 선택한 페이지 번호 값 세팅 (필수)
      this.selectDocList();
    },
    selectDocList() {
      this.$emit("get-doc-list", this.paginateConfig, this.sort);
    },
    async toggleReadYn() {
      const targetPKs = this.checkedDocs;
      const existNoReadDoc = targetPKs
        .map((pk) => this.docList.find((e) => e.targetPk === pk))
        .some((e) => e.readDate == null);

      if (isEmpty(targetPKs)) {
        this._showLayer({ type: "alert", content: "선택된 문서가 없습니다." });
        return;
      }

      const params = {
        targetGubun: targetGubun.DF_SETTLEMENT,
        targetPkList: targetPKs,
        readYn: existNoReadDoc,
      };

      if (this.docStatus) {
        params.docStatus = this.docStatus;
      }
      if (this.aprvlStatus) {
        params.aprvlStatus = this.aprvlStatus;
      }

      const res = await ApiService.shared.put(this.updateReadApi, params);

      if (this.checkNormalResponse(res)) {
        this.resetSort(this.currentPage);
      }
    },
    deleteConfirm() {
      if (!this.checkedDocs || this.checkedDocs.length === 0) {
        this._showLayer({ type: "alert", content: "선택된 문서가 없습니다." });
        return;
      }

      this._showLayer(
        { content: "삭제를 진행하시겠습니까?", type: "confirm" },
        this.deleteTempDocs,
      );
    },
    async deleteTempDocs() {
      if (!this.targetGubun) {
        this._showLayer({
          type: "alert",
          content: "오류가 발생하였습니다.<br>관리자에게 문의하세요.",
        });
        return;
      }
      const data = {
        targetGubun: this.targetGubun,
        targetPkList: this.checkedDocs,
      };
      const res = await ApiService.shared.delete(this.deleteTempApi, {
        data,
      });

      if (!this.checkNormalResponse(res)) {
        return;
      }

      this._showToast({ content: "삭제되었습니다." });
      this.setPaging(this.paginateConfig.pageCurrent);
    },
    docDetail(document) {
      this.$emit("go-detail", document.targetPk);
    },
    onSort(sortField) {
      if (sortField === "DRAFT_DATE") {
        if (this.sort.sortField !== sortField) {
          this.sort.sortField = sortField;
          this.sort.sortOnDraft = "";
          this.sort.sortFlagDraft = "DESC";
        } else {
          if (this.sort.sortFlagDraft === "DESC") {
            this.sort.sortOnDraft = "sort_on";
            this.sort.sortFlagDraft = "ASC";
          } else {
            this.sort.sortOnDraft = "";
            this.sort.sortFlagDraft = "DESC";
          }
        }
        this.sort.sortOnComplete = "";
        this.sort.sortFlagComplete = "DESC";
      } else {
        if (this.sort.sortField !== sortField) {
          this.sort.sortField = sortField;
          this.sort.sortOnComplete = "";
          this.sort.sortFlagComplete = "DESC";
        } else {
          if (this.sort.sortFlagComplete === "DESC") {
            this.sort.sortOnComplete = "sort_on";
            this.sort.sortFlagComplete = "ASC";
          } else {
            this.sort.sortOnComplete = "";
            this.sort.sortFlagComplete = "DESC";
          }
        }
        this.sort.sortOnDraft = "";
        this.sort.sortFlagDraft = "DESC";
      }
      this.setPaging(this.paginateConfig.pageCurrent);
    },
    toggleAll() {
      if (this.checkedDocs && this.checkedDocs.length === this.totalDocCount) {
        this.checkedDocs = [];
        this.checkAll = false;
      } else {
        this.checkedDocs = [];
        this.docList.forEach((doc) => {
          this.checkedDocs.push(doc.targetPk);
        });
        this.checkAll = true;
      }
    },
    resetSort(page) {
      this.sort = { ...initSort };
      this.setPaging(page);
    },
  },
};
</script>
